var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "clientForm",
      staticClass: "form-wrapper",
      attrs: { "label-width": "160px", model: _vm.client, rules: _vm.rules },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Name", prop: "name" } },
        [
          _c(
            "el-input",
            {
              attrs: { readonly: _vm.readonly },
              model: {
                value: _vm.client.name,
                callback: function ($$v) {
                  _vm.$set(_vm.client, "name", $$v)
                },
                expression: "client.name",
              },
            },
            [
              _c("el-button", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.client.name,
                    expression: "client.name",
                    arg: "copy",
                  },
                ],
                staticClass: "copy-button",
                attrs: {
                  slot: "append",
                  size: "small",
                  icon: "el-icon-document-copy",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Name")
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.readonly || _vm.client.tax_number
        ? _c(
            "el-form-item",
            { attrs: { label: "ABN", prop: "tax_number" } },
            [
              _c(
                "el-input",
                {
                  attrs: { readonly: _vm.readonly },
                  model: {
                    value: _vm.client.tax_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "tax_number", $$v)
                    },
                    expression: "client.tax_number",
                  },
                },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.client.tax_number,
                        expression: "client.tax_number",
                        arg: "copy",
                      },
                    ],
                    staticClass: "copy-button",
                    attrs: {
                      slot: "append",
                      size: "small",
                      icon: "el-icon-document-copy",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showNotification("ABN")
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.readonly || _vm.client.first_name || _vm.client.email
        ? _c("hr")
        : _vm._e(),
      !_vm.readonly || _vm.client.first_name
        ? _c(
            "el-form-item",
            { attrs: { label: "Primary person" } },
            [
              _c("el-input", {
                staticClass: "half",
                attrs: { placeholder: "First name", readonly: _vm.readonly },
                model: {
                  value: _vm.client.first_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.client, "first_name", $$v)
                  },
                  expression: "client.first_name",
                },
              }),
              _c(
                "el-input",
                {
                  staticClass: "half",
                  attrs: { placeholder: "Last name", readonly: _vm.readonly },
                  model: {
                    value: _vm.client.last_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "last_name", $$v)
                    },
                    expression: "client.last_name",
                  },
                },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: `${_vm.client.first_name} ${
                          _vm.client.last_name ? _vm.client.last_name : ""
                        }`,
                        expression:
                          "\n                    `${client.first_name} ${\n                        client.last_name ? client.last_name : ''\n                    }`\n                ",
                        arg: "copy",
                      },
                    ],
                    staticClass: "copy-button",
                    attrs: {
                      slot: "append",
                      size: "small",
                      icon: "el-icon-document-copy",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showNotification("Contact")
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.readonly || _vm.client.email
        ? _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    readonly: _vm.readonly,
                    "suffix-icon": "el-icon-message",
                  },
                  model: {
                    value: _vm.client.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "email", $$v)
                    },
                    expression: "client.email",
                  },
                },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.client.email,
                        expression: "client.email",
                        arg: "copy",
                      },
                    ],
                    staticClass: "copy-button",
                    attrs: {
                      slot: "append",
                      size: "small",
                      icon: "el-icon-document-copy",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showNotification("Email")
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.readonly || _vm.client.additional_contacts?.length
        ? [
            _vm._l(_vm.client.additional_contacts, function (contact, index) {
              return [
                _c("hr"),
                _c(
                  "el-form-item",
                  {
                    key: `${index}_name`,
                    attrs: { label: "Additional person" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "half",
                      attrs: {
                        placeholder: "First name",
                        readonly: _vm.readonly,
                      },
                      model: {
                        value: contact.first_name,
                        callback: function ($$v) {
                          _vm.$set(contact, "first_name", $$v)
                        },
                        expression: "contact.first_name",
                      },
                    }),
                    _c(
                      "el-input",
                      {
                        staticClass: "half",
                        attrs: {
                          placeholder: "Last name",
                          readonly: _vm.readonly,
                        },
                        model: {
                          value: contact.last_name,
                          callback: function ($$v) {
                            _vm.$set(contact, "last_name", $$v)
                          },
                          expression: "contact.last_name",
                        },
                      },
                      [
                        _c("el-button", {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: `${contact.first_name} ${
                                contact.last_name ? contact.last_name : ""
                              }`,
                              expression:
                                "\n                            `${contact.first_name} ${\n                                contact.last_name ? contact.last_name : ''\n                            }`\n                        ",
                              arg: "copy",
                            },
                          ],
                          staticClass: "copy-button",
                          attrs: {
                            slot: "append",
                            size: "small",
                            icon: "el-icon-document-copy",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showNotification("Contact")
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    key: `${index}_email`,
                    staticClass: "additional_email",
                    attrs: { label: "Email", prop: "email" },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          readonly: _vm.readonly,
                          "suffix-icon":
                            _vm.readonly && contact.include_in_emails
                              ? "el-icon-message"
                              : null,
                        },
                        model: {
                          value: contact.email,
                          callback: function ($$v) {
                            _vm.$set(contact, "email", $$v)
                          },
                          expression: "contact.email",
                        },
                      },
                      [
                        _c("el-button", {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: contact.email,
                              expression: "contact.email",
                              arg: "copy",
                            },
                          ],
                          staticClass: "copy-button",
                          attrs: {
                            slot: "append",
                            size: "small",
                            icon: "el-icon-document-copy",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showNotification("Email")
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            }),
            _c(
              "div",
              { staticStyle: { "text-align": "right" } },
              [
                !_vm.readonly
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.handleAddContact },
                      },
                      [_vm._v(" Add another contact ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.readonly || _vm.addresses.length ? _c("hr") : _vm._e(),
      !_vm.readonly || _vm.addresses.length
        ? _c(
            "el-form-item",
            { attrs: { label: "Address" } },
            _vm._l(_vm.addresses, function (a, index) {
              return _c(
                "div",
                { key: `address_${index}` },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        readonly: _vm.readonly,
                        placeholder: "Street address",
                      },
                      model: {
                        value: a.address,
                        callback: function ($$v) {
                          _vm.$set(a, "address", $$v)
                        },
                        expression: "a.address",
                      },
                    },
                    [
                      _c("el-button", {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: `${a.address}, ${a.city}, ${a.country}`,
                            expression:
                              "\n                        `${a.address}, ${a.city}, ${a.country}`\n                    ",
                            arg: "copy",
                          },
                        ],
                        staticClass: "copy-button",
                        attrs: {
                          slot: "append",
                          size: "small",
                          icon: "el-icon-document-copy",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showNotification("Address")
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: { readonly: _vm.readonly, placeholder: "City" },
                    model: {
                      value: a.city,
                      callback: function ($$v) {
                        _vm.$set(a, "city", $$v)
                      },
                      expression: "a.city",
                    },
                  }),
                  _c("el-input", {
                    attrs: { readonly: _vm.readonly, placeholder: "Country" },
                    model: {
                      value: a.country,
                      callback: function ($$v) {
                        _vm.$set(a, "country", $$v)
                      },
                      expression: "a.country",
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.readonly
        ? _c(
            "el-button",
            {
              staticClass: "submit",
              attrs: { loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.$emit("submit")
                },
              },
            },
            [_vm._v(" Create ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }